import React, { useState, useEffect } from "react";
import './Categories.css';

function Categories({ selectedCategory, onCategoryChange }) {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/categories`);
            const jsonData = await response.json();
            setCategories(jsonData.data);
        };
        fetchCategories();
    }, []);

    return (
        <div id="categories">
            <p className={selectedCategory === "" ? "selected" : ""} onClick={e => onCategoryChange("")}>
                Alla
            </p>
            {categories.map((category) => (
                <p className={selectedCategory === category.attributes.Name ? "selected" : ""} key={category.id} onClick={e => onCategoryChange(category.attributes.Name)}>
                    {category.attributes.Name}
                </p>
            ))}
        </div>
    );
}

export default Categories;