import React, { useContext } from "react";
import { CartContext } from '../../context/CartContext';

function CartItem({ id, offerCode, title, cover }) {
  const { dispatchCartEvent } = useContext(CartContext);
  const handleRemoveItem = () => {
      dispatchCartEvent('REMOVE_ITEM', { title, cover, id, offerCode });
	};

  return (
    <div className="cart-item">
        <div className="cart-item-cover">
          <img src={cover} alt={title} />
        </div>
        <p className="cart-item-title">{title}</p>
        <p className="remove-cart-item" onClick={handleRemoveItem}>Ta bort</p>
    </div>
  );
}
export default CartItem;