import React, { useContext, useState } from "react";
import './Form.css';
import { CartContext } from '../../context/CartContext';

function Form({onSuccess}) {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState([]);
  const { cart } = useContext(CartContext);
  const inputFields = [
    {
      id: "firstname",
      name: "firstName",
      type: "text",
      placeholder: "Förnamn",
      required: true
    },
    {
      id: "lastname",
      name: "lastName",
      type: "text",
      placeholder: "Efternamn",
      required: true
    },
    {
      id: "email",
      name: "email",
      type: "email",
      placeholder: "E-mail",
      required: true
    },
    {
      id: "phone",
      name: "phone",
      type: "tel",
      placeholder: "Telefonnummer",
      required: true,
    },
    {
      id: "adress",
      name: "address",
      type: "text",
      placeholder: "Address",
      required: true
    },
    {
      id: "zip-code",
      name: "zipCode",
      type: "text",
      placeholder: "Postnummer",
      required: true
    },
    {
      id: "city",
      name: "city",
      type: "text",
      placeholder: "Stad",
      required: true
    },
    /*
    {
      id: "country",
      name: "country",
      type: "select",
      placeholder: "Land",
      options: [
        {value: "sweden", label: "Sverige"},
        {value: "denmark", label: "Danmark"},
        {value: "germany", label: "Germany"},
      ],
      required: true
    },
    */
    {
      id: "birthday",
      name: "birthday",
      type: "date",
      placeholder: "Födelsedag",
      required: true
    },
    {
      id: "title",
      name: "title",
      type: "text",
      placeholder: "Yrkestitel",
      required: true    
    },
    {
      id: "company-name",
      name: "companyName",
      type: "text",
      placeholder: "Företagsnamn",
      required: true
    },
  ];


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleErrors = (errors) => {
    // Set custom error messages based on error message contains
    errors.forEach(error => {
      if(error.message.includes("This attribute must be unique")){
        if(error.path[0] === "email"){
          error.message = "Den här e-postadressen är redan registrerad";
        }
      }
      else if(error.message.includes("must match the following")){
        error.message = "Vänligen kontrollera att du har angett rätt format (+46701234567)";
      }
    });
    
    setFormErrors(errors);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(event.target.checkValidity()) {
      fetch(`${process.env.REACT_APP_BACKEND}/api/subscribers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            ...formData,
            cart,
            magazines: cart.map(item => item.id)
          }
        })
      })
      .then(response => response.json())
      .then(data => {
        if(data.error){
          if(data.error){
            handleErrors(data.error.details["errors"]);
            console.log(formErrors);
          }
        }
        else{
          onSuccess();
        }
      })
      .catch(() => {
        handleErrors({message: "Något gick fel, försök igen senare."});
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div id="form-fields">
        {inputFields.map((inputField, index) => (
          <label key={index} id={inputField.id + "-label"}>
            <span>{inputField.placeholder}:</span>
            {inputField.type === "select" && (
              <select
                id={inputField.id}
                name={inputField.name}
                className="form-input"
                value={formData[inputField.name] || ""}
                required={inputField.required}
                onChange={handleInputChange}
              >
                <option value="" disabled hidden>Välj ett alternativ</option>
                {inputField.options.map((option, index) => (
                  <option key={index} value={option.value}>{option.label}</option>
                ))}
              </select>
            )}
            {inputField.type !== "select" && (
              <input
                id={inputField.id}
                type={inputField.type}
                name={inputField.name}
                className="form-input"
                value={formData[inputField.name] || ""}
                required={inputField.required}
                pattern={inputField.pattern}
                onChange={handleInputChange}
              />
            )}
            {formErrors.map((error, index) => (
              error.path[0] === inputField.name && <p key={index} className="error">{error.message}</p>
            ))}
          </label>
        ))}
      </div>
      
      <button type="submit">Beställ</button>
      <p className="disclaimer">Beställningen är kostnadsfri för dig som kund eller partner till Ingager.</p>
    </form>
  );
}

export default Form;
