import React, { useContext, useState } from "react";
import './Magazine.css';
import { CartContext } from '../../context/CartContext';
import { GeneralContext,  } from '../../context/GeneralContext';

function Magazine({ id, offerCode, title, cover }) {
  const [loaded, setLoaded] = useState(false);

  const { cart, dispatchCartEvent } = useContext(CartContext);
  const config  = useContext(GeneralContext);
  const handleAddItem = () => {
    if(!itemAdded() && cart.length < config.maxProducts) {
      dispatchCartEvent('ADD_ITEM', { title, cover, id, offerCode });
    }
    else {
      dispatchCartEvent('REMOVE_ITEM', { title, cover, id, offerCode });
    }
	};

  function itemAdded() {
    if(cart.some(item => item.title === title))
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  return (
    <div className="magazine">
      
      <div className="magazine-cover">
        <img onLoad={() => setLoaded(true)}  style={loaded ? {} : { display: 'none' }} src={cover} alt={title} />
      </div>
      <div className="magazine-info">
        <h1>{title}</h1>
        <button className={itemAdded() ? 'add-magazine added' : 'add-magazine'} onClick={handleAddItem}>{itemAdded() ? 'Vald' : 'Välj'}</button>
      </div>
    </div>
  );
}
export default Magazine;