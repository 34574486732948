import React, { useState, useEffect, } from "react";
import Magazine from "../magazine/Magazine";
import './MagazineList.css';

function MagazineList({selectedCategory}) {
  const [magazines, setMagazines] = useState([]);

  const filterMagazinesByCategory = (category) => {
      
      if (!category) {
        return magazines;
      }

      const filteredMagazines = magazines.filter((magazine) => {
        const categories = magazine.attributes.categories.data;
        return categories.some((categoryObj) => categoryObj.attributes.Name === category);
      });
      return filteredMagazines;
  }
  
  useEffect(() => {
    const fetchMagazines = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/magazines?populate[0]=categories`);
      const jsonData = await response.json();
      setMagazines(jsonData.data);
    };
    fetchMagazines()
  }, []);

  return (
    <div id="magazines">
      <div className="magazines-grid">
        {filterMagazinesByCategory(selectedCategory).map((magazine) => (
          <Magazine key={magazine.id} id={magazine.id} offerCode={magazine.attributes.offerCode} title={magazine.attributes.Titel} cover={magazine.attributes.coverImageFullURL}/>
        ))}
      </div>
    </div>
  );
}

export default MagazineList;