import React, { useContext } from "react";
import './Cart.css';
import { CartContext } from '../../context/CartContext';
import CartItem from "./CartItem";

function Cart({onContinue, onCloseCart}) {
  const { cart } = useContext(CartContext);

  return (
    <div id="cart" >
      <button id="close-cart" onClick={() => onCloseCart()}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <line x1="15" y1="9" x2="9" y2="15"/>
          <line x1="9" y1="9" x2="15" y2="15"/>
        </svg>
      </button>

      <div id="cart-inner-container" >
        <h1 id="cart-title">Varukorg</h1>

        <div id="cart-items">
          {cart.map((item, index) => (
            <CartItem key={index} title={item.title} cover={item.cover}/>
          ))}
        </div>

        <button id="cart-button" onClick={() => onContinue()}>Fortsätt</button>
      </div>
    </div>
  );
}

export default Cart;