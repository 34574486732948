import React, { useContext, useState, useEffect } from "react";
import './Checkout.css';
import Form from "../form/Form";
import { CartContext } from '../../context/CartContext';

function Checkout({onCloseCheckout}) {
  const { cart } = useContext(CartContext);
  const [formSuccess, setFormSuccess] = useState(false);

  const handleSuccess = () => {
    setFormSuccess(true);
  }

  useEffect(() => {
    const handleEsc = (event) => {
       if (event.keyCode === 27) {
        onCloseCheckout();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onCloseCheckout]);

  return (
    <div id="checkout">
      <div id="checkout-backdrop" onClick={() => onCloseCheckout()}></div>
      <div id="checkout-inner">
        {!formSuccess && (
        <>
          <button id="close-checkout" onClick={() => onCloseCheckout()}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <line x1="15" y1="9" x2="9" y2="15"/>
              <line x1="9" y1="9" x2="15" y2="15"/>
            </svg>
          </button>
          <h1 id="checkout-title">Översikt</h1>
          <div id="magazines">
            {cart.map((item) => (
              <div key={item.title} className="magazine">
                <div className="magazine-cover">
                  <img src={item.cover} alt={item.title} />
                </div>
                <div className="magazine-info">
                  <h1>{item.title}</h1>
                </div>
              </div>
            ))}
          </div>
          <div id="form-container">
            <Form onSuccess={handleSuccess}/>
          </div>
        </>
        )}
      </div>
      {formSuccess && ( 
        <div id="form-success">
          <h1>Tack för din beställning!</h1>
          <h1>Kontakta <href a="mailto:info@ingager.com">info@ingager.com</href> om du har frågor.</h1>
        </div>
      )}
    </div>
  );
}

export default Checkout;
