import React, { useState, useEffect } from "react";
import './App.css';
import Header from "./components/header/Header";
import Categories from "./components/categories/Categories";
import MagazineList from "./components/magazineList/MagazineList";
import Checkout from "./components/checkout/Checkout";
import { GeneralContext } from "./context/GeneralContext";
import { CartContext } from "./context/CartContext";
import Cart from "./components/cart/Cart";

function App() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [cart, setCart] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [goToCheckout, setGoToCheckout] = useState(false);
  const maxProducts = 2;

  const dispatchCartEvent = (actionType, payload) => {
		switch (actionType) {
			case 'ADD_ITEM':
				setCart([ ...cart, payload ]);
				return;
			case 'REMOVE_ITEM':
				setCart(cart.filter(item => item.title !== payload.title));
				return;
			default:
				return;
		}
	};

  useEffect(() => {
    if(cart.length > 0){  
      handleShowCart();
    }
    if(cart.length === 0){  
      handleHideCart();
    }
  }, [cart.length]);

  function handleCategoryChange(category) {
    setSelectedCategory(category);
  }

  function handleShowCart() {
    setShowCart(true);
  }
  function handleHideCart() {
    setShowCart(false);
  }

  function toggleGoToCheckout() {
    setGoToCheckout(!goToCheckout);
  }

  return (
    <GeneralContext.Provider value={{ maxProducts }}>
      <CartContext.Provider value={{ cart, dispatchCartEvent }}>
        <div id="app" className={`${showCart ? "show-cart" : ""} ${goToCheckout ? "disable-scroll" : ""}`}>
          <div id="main">
            <Header />
            <Categories selectedCategory={selectedCategory} onCategoryChange={handleCategoryChange} />
            <MagazineList selectedCategory={selectedCategory}/>
          </div>
          {cart.length > 0 && showCart && (
            <Cart onContinue={toggleGoToCheckout} onCloseCart={handleHideCart}/>
          )}
          {goToCheckout && (
              <Checkout onCloseCheckout={toggleGoToCheckout}/>
          )}
        </div>
      </CartContext.Provider>
    </GeneralContext.Provider>
  );
}

export default App;
