import React, { useEffect, useState } from "react";
import './Header.css';
import logo from '../../ingager_logo.png';

function Header() {
  const [header, setHeader] = useState({
    logo,
    subheader: 'Du får två tidningsprenumerationer, helt utan kostnad, som tack för samarbetet med Ingager. Välj och vraka nedan!'
  });

  useEffect(() => {
      const fetchCategories = async () => {
          const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/header?populate=logo`);
          const jsonData = await response.json();
          setHeader({
            logo: `${process.env.REACT_APP_BACKEND}${jsonData.data.attributes.logo.data.attributes.url}`,
            subheader: jsonData.data.attributes.subheader
          });
      };
      fetchCategories();
  }, []);


  return (
    <div id="header">
      <img src={header.logo}  alt="Ingager logo" />
      <h3>
      {header.subheader}
      </h3>
    </div>
  );
}

export default Header;